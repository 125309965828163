<template>
  <div class="functional">
    <div class="title">功能特点</div>
    <div class="main-box">
      <div v-for="(item,index) in list" class="item" :key="index">
        <img class="icon" :src="require(`@/assets/icons/productCenter/${item.icon}`)" alt="功能特点" srcset="">
        <div class="tt">{{item.title}}</div>
        <div class="intro">{{item.intro}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list']
}
</script>

<style lang="scss" scoped>
.functional {
  padding-top: 160px;
  padding-bottom: 112px;
  .title {
    font-size: 36px;
    color: #303b50;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 80px;
  }
  .main-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      background: #f9fbff;
      box-shadow: 0px 4px 16px 0px rgba(31, 37, 81, 0.11);
      border-radius: 2px;
      background-size: 400px 462px;
      background-repeat: no-repeat;
      background-position: 119px 86px;
      margin-bottom: 48px;
      &:hover {
        background-image: url('~@/assets/image/productCenter/functional-bg.png');
      }
      .icon {
        width: 60px;
        height: 60px;
        margin-bottom: 26px;
      }
      .tt {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 24px;
        color: #303b50;
      }
      .intro {
        font-size: 14px;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
</style>