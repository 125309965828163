<template>
  <div>
    <Banner>
      <img style="width:532px;height:378px;bottom:45px" src="@/assets/image/productCenter/personCert-banner-bg.png" alt="个人证书">
      <template v-slot:text>
        <div class="title">个人证书</div>
        <div class="hint-multi">个人数字证书是由豸信CA颁发的，用以标识个人在网络中的数字身份。数字证书以密码学为基础，采用数字签名、数字信封、时间戳服务等技术，在互联网上建立起有效的信任机制。用户使用此证书在互联网中标识证书持有人的数字身份，用来保证信息在互联网传输过程中的安全性和完整性。</div>
        <el-button @click="verify('certApply')" type="primary">立即申请</el-button>
      </template>
    </Banner>
    <Functional class="functional" :list="functional" />
    <Features :list="features" />
    <LearnMore />
  </div>
</template>

<script>
import Features from './components/Features.vue'
import Functional from './components/Functional.vue'
import LearnMore from './components/LearnMore.vue'
export default {
  components: { Features, Functional, LearnMore },
  data () {
    return {
      functional: [
        {
          icon: 'functional-icon4.png',
          title: '身份一致性',
          intro:
            '通过数字证书确认网络中传递信息的个人身份，确保个人线上身份同线下身份一致。'
        },
        {
          icon: 'functional-icon3.png',
          title: '合法性',
          intro:
            '利用数字证书进行数字签名，其作用与手写的签名具有同等法律效力。'
        },
        {
          icon: 'functional-icon5.png',
          title: '信息安全',
          intro:
            '数字证书可以帮助发送方使用接收方的公钥对数据加密，而接收方则使用自己的私钥解密，实现信息安全传输。'
        }
      ],
      features: [
        {
          title: '安全性',
          intro:
            '通过使用数字证书对信息加密，只有接收方才能阅读加密的信息，从而保证信息不会被他人窃取。'
        },
        {
          title: '完整性',
          intro:
            '利用数字证书可以校验传送的信息在传递的过程中是否被篡改过或丢失。'
        },
        {
          title: '便利性',
          intro:
            '用户根据实际需要，即时申请、开通并使用数字证书，用户不需要掌握加密技术或原理，就能够直接通过PKI/CA技术进行安全防护，便捷高效。'
        },
        {
          title: '实用性',
          intro:
            '个人数字证书已经广泛地应用到现实生活的各个领域：网上签约、网上订购、网上缴费、网上缴税等等。'
        }
      ]
    }
  },
  methods: {
    verify (name) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.verify(name)
      })
      this.$api.getAccountInfo().then((res) => {
        if (res.data.verified) {
          let routeData = this.$router.resolve({ name })
          window.open(routeData.href, '_blank')
        } else {
          this.$confirm('当前账号未完成管理员实名认证，实名之后才可办理业务。', '提示', {
            confirmButtonText: '实名认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/personHome' })
          }).catch(() => {

          });
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.functional {
  .item {
    width: 372px;
    height: 358px;
    padding: 32px 42px;
  }
}
</style>