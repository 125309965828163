<template>
  <div class="features">
    <div class="title">产品特性</div>
    <div class="main-box">
      <div v-for="(item,index) in list" class="item" :key="index">
        <div class="tt">{{item.title}}</div>
        <div class="intro">{{item.intro}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default { props: ['list'] }
</script>

<style lang="scss" scoped>
.features {
  background: #f9fbff;
  padding-top: 98px;
  padding-bottom: 86px;
  .title {
    font-size: 36px;
    color: #303b50;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 80px;
  }
  .main-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      width: 600px;
      height: 146px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(31, 37, 81, 0.11);
      border-radius: 2px;
      border: 1px solid #ffffff;
      color: #303b50;
      padding: 28px 32px;
      background-size: 80px 83px;
      background-repeat: no-repeat;
      background-position: right bottom;
      margin-bottom: 32px;
      &:hover {
        background-image: url('~@/assets/image/productCenter/features-bg.png');
      }
      .tt {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 16px;
      }
      .intro {
        width: 506px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
</style>